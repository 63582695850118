import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  MenuItem,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import { AuctionItemService } from "../../services/AuctionItemService";
import { fetchCategories } from "../../services/CategoryService";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import UserStatsTable from "./UserStatsTable";

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    border: "1px solid #89939E",
    backgroundColor: "transparent",
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    py: "10px",
  },
};

const EditLot = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [startingPrice, setStartingPrice] = useState("");
  const [currentPrice, setCurrentPrice] = useState("");
  const [increment, setIncrement] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("inactive");
  const [categories, setCategories] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    const fetchLot = async () => {
      try {
        const data = await AuctionItemService.fetchAuctionItemById(id);
        setName(data.name);
        setDescription(data.description || "");
        setCategory(data.category || "");
        setStartingPrice(data.startingPrice || "");
        setCurrentPrice(data.currentPrice || "");
        setIncrement(data.increment || "");
        setStartDate(data.startDate ? new Date(data.startDate) : "");
        setEndDate(data.endDate ? new Date(data.endDate) : "");
        setStatus(data.status);
      } catch (error) {
        console.error("Ошибка при получении лота:", error);
      }
    };

    const loadCategories = async () => {
      try {
        const categoryData = await fetchCategories();
        setCategories(categoryData);
      } catch (error) {
        console.error("Ошибка при загрузке категорий:", error);
      }
    };

    fetchLot();
    loadCategories();
  }, [id]);

  const handleUpdate = async () => {
    const updatedLot = {
      name,
      description,
      category,
      startingPrice: parseFloat(startingPrice),
      currentPrice: parseFloat(currentPrice),
      increment: parseFloat(increment),
      startDate,
      endDate,
      status,
    };

    try {
      await AuctionItemService.updateAuctionItem(id, updatedLot);
      navigate("/admin/lots");
    } catch (error) {
      console.error("Ошибка при обновлении лота:", error);
    }
  };

  const handleDelete = async () => {
    try {
      await AuctionItemService.deleteAuctionItem(id);
      navigate("/admin/lots");
    } catch (error) {
      console.error("Ошибка при удалении лота:", error);
    }
  };

  const handleDeleteDialogOpen = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <Paper sx={{ borderRadius: "10px", boxShadow: "none", p: 3 }}>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
        sx={{
          borderRadius: "8px",
          textTransform: "none",
          color: "#3083FF",
          borderColor: "#3083FF",
          fontWeight: 400,
          "&:hover": {
            borderColor: "#3083FF",
            backgroundColor: "rgba(48, 131, 255, 0.08)",
          },
        }}>
        Назад
      </Button>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, my: 3 }}>
        Редактировать лот
      </Typography>
      <Box sx={{ display: "flex", gap: 3 }}>
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 2 }}>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Наименование</Typography>
            <TextField
              placeholder="Наименование"
              variant="outlined"
              fullWidth
              sx={textFieldStyles}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Категория</Typography>
            <TextField
              select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              variant="outlined"
              sx={{ ...textFieldStyles, width: "100%" }}
              displayEmpty
              SelectProps={{
                displayEmpty: true,
                renderValue: (value) =>
                  categories.find((cat) => cat._id === value)?.name || "Категория",
              }}>
              {categories.map((cat) => (
                <MenuItem key={cat._id} value={cat._id}>
                  {cat.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
              Начальная стоимость
            </Typography>
            <TextField
              placeholder="Начальная стоимость"
              variant="outlined"
              fullWidth
              sx={textFieldStyles}
              value={startingPrice}
              onChange={(e) => setStartingPrice(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                Текущая ставка
              </Typography>
              <TextField
                placeholder="Текущая ставка"
                variant="outlined"
                fullWidth
                sx={textFieldStyles}
                value={currentPrice}
                onChange={(e) => setCurrentPrice(e.target.value)}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Шаг</Typography>
              <TextField
                placeholder="Шаг"
                variant="outlined"
                fullWidth
                sx={textFieldStyles}
                value={increment}
                onChange={(e) => setIncrement(e.target.value)}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                Дата начала
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                <DateTimePicker
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "100%", ...textFieldStyles }}
                  value={dayjs(startDate)}
                  onChange={(newValue) => setStartDate(newValue)}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                Дата окончания
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                <DateTimePicker
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "100%", ...textFieldStyles }}
                  value={dayjs(endDate)}
                  onChange={(newValue) => setEndDate(newValue)}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Статус</Typography>
            <TextField
              select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              variant="outlined"
              sx={textFieldStyles}
              displayEmpty
              SelectProps={{
                displayEmpty: true,
                renderValue: (value) => value || "Выберите статус",
              }}>
              <MenuItem value="inactive">Неактивный</MenuItem>
              <MenuItem value="active">Активный</MenuItem>
              <MenuItem value="completed">Завершен</MenuItem>
            </TextField>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Описание</Typography>
            <ReactQuill
              value={description}
              onChange={setDescription}
              theme="snow"
              placeholder="Описание"
            />
          </Box>
          <UserStatsTable auctionId={id} />
        </Box>
        <Box sx={{ width: 360, display: "flex", flexDirection: "column", gap: 2 }}>
          <Button
            variant="contained"
            onClick={handleUpdate}
            sx={{
              background:
                "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
              boxShadow: "none",
              borderRadius: "10px",
              p: "14px 34px",
              fontSize: "14px",
              fontWeight: 400,
              textTransform: "none",
            }}>
            Сохранить изменения
          </Button>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleDeleteDialogOpen}
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              fontWeight: 400,
            }}>
            Удалить лот
          </Button>
        </Box>
      </Box>

      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Подтверждение удаления</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Вы уверены, что хотите удалить этот лот? Это действие нельзя отменить.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Отмена
          </Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default EditLot;
