import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToMain = () => {
    navigate("/");
  };

  const navigateToRef = () => {
    navigate("/profile/referral-program");
  };

  const navigateToPrivacyPolicy = () => {
    navigate("/privacy-policy");
  };

  const navigateToOfferTerms = () => {
    navigate("/offer-terms");
  };

  const navigateToTerms = () => {
    navigate("/terms");
  };

  const navigateToPaymentAndDelivery = () => {
    navigate("/payment-and-delivery");
  };

  const navigateToContacts = () => {
    navigate("/contacts");
  };

  return (
    <Box sx={{ flexGrow: 1, p: "40px 0" }}>
      <Grid container>
        <Grid item lg={3} xs={12} sx={{ ml: 0 }}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            onClick={navigateToPrivacyPolicy}
            sx={{
              p: "10px",
              display: "flex",
              color: "black",
              fontSize: "14px",
              fontWeight: 400,
              textDecoration: "none",
              cursor: "pointer",
            }}>
            {t("footer.privacyPolicy")}
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            onClick={navigateToOfferTerms}
            sx={{
              p: "10px",
              display: "flex",
              color: "black",
              fontSize: "14px",
              fontWeight: 400,
              textDecoration: "none",
              cursor: "pointer",
            }}>
            {t("footer.offerTerms")}
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            onClick={navigateToRef}
            sx={{
              p: "10px",
              display: "flex",
              color: "black",
              fontSize: "14px",
              fontWeight: 400,
              textDecoration: "none",
            }}>
            {t("footer.referralProgram")}
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            sx={{
              p: "10px",
              display: "flex",
              color: "black",
              fontSize: "14px",
              fontWeight: 400,
              textDecoration: "none",
            }}>
            {t("footer.copyright")}
          </Typography>
        </Grid>
        <Grid item lg={6} xs={12} sx={{ mt: { lg: 0, xs: "40px" }, mb: { lg: 0, xs: "40px" } }}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            onClick={navigateToMain}
            sx={{
              p: "10px",
              display: "flex",
              color: "black",
              fontSize: "14px",
              fontWeight: 400,
              textDecoration: "none",
              cursor: "pointer",
            }}>
            {t("footer.main")}
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            onClick={navigateToTerms}
            sx={{
              p: "10px",
              display: "flex",
              color: "black",
              fontSize: "14px",
              fontWeight: 400,
              textDecoration: "none",
              textAlign: "center",
              cursor: "pointer",
            }}>
            {t("footer.terms")}
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            onClick={navigateToPaymentAndDelivery}
            sx={{
              p: "10px",
              display: "flex",
              color: "black",
              fontSize: "14px",
              fontWeight: 400,
              textDecoration: "none",
              cursor: "pointer",
            }}>
            {t("footer.paymentAndDelivery")}
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            onClick={navigateToContacts}
            sx={{
              p: "10px",
              display: "flex",
              color: "black",
              fontSize: "14px",
              fontWeight: 400,
              textDecoration: "none",
              cursor: "pointer",
            }}>
            {t("footer.contacts")}
          </Typography>
        </Grid>
        <Grid item lg={3} xs={12}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            sx={{
              p: "10px",
              display: "flex",
              color: "black",
              fontSize: "18px",
              fontWeight: 600,
              textDecoration: "none",
            }}>
            {t("footer.contactCenter")}
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            sx={{
              p: "10px",
              display: "flex",
              color: "#464646",
              fontSize: "18px",
              fontWeight: 600,
              textDecoration: "none",
            }}>
            {t("footer.phone")}
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            sx={{
              p: "10px",
              display: "flex",
              color: "#464646",
              fontSize: "14px",
              fontWeight: 400,
              textDecoration: "none",
            }}>
            {t("footer.email")}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
