import React, { useState, useEffect } from "react";
import { Card, CardContent, CardMedia, Typography, Box, Button, IconButton } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useFavorite } from "../../Context/FavoriteContext";
import { useAuth } from "../../Context/AuthContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuctionItemService } from "../../services/AuctionItemService";

const CardItem = ({ item, type }) => {
  const { t } = useTranslation();
  const { favorites, addFavorite, removeFavorite } = useFavorite();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [timeUntilStart, setTimeUntilStart] = useState("");

  useEffect(() => {
    const calculateTimeUntilStart = async (startDate, itemId) => {
      if (!startDate || !itemId) {
        console.error("Missing startDate or itemId");
        return "Ошибка времени";
      }

      const now = new Date();
      const start = new Date(startDate);

      const difference = start - now;

      if (difference <= 0) {
        try {
          await AuctionItemService.updateAuctionItemStatus(itemId, { status: "active" });
          console.log("Auction status updated to active");
        } catch (error) {
          console.error("Error updating auction status:", error);
        }
        return "Аукцион начался";
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

      return `${days}дн : ${hours}ч : ${minutes}мин`;
    };

    const updateTime = async () => {
      if (item && item.startDate && item._id) {
        const calculatedTime = await calculateTimeUntilStart(item.startDate, item._id);
        setTimeUntilStart(calculatedTime);
      } else {
        console.error("Missing item or startDate");
      }
    };

    updateTime();

    const intervalId = setInterval(updateTime, 60000); // Update every 1 minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [item]);

  const handleFavoriteClick = async () => {
    if (!user) {
      navigate("/login");
      return;
    }

    const isFavorite = favorites.some((favorite) => favorite._id === item._id);
    if (isFavorite) {
      await removeFavorite(item);
    } else {
      await addFavorite(item);
    }
  };

  const handleParticipateClick = () => {
    navigate(`/lots/${item._id}`);
  };

  const isFavorite = favorites.some((favorite) => favorite._id === item._id);

  return (
    <Card
      sx={{
        borderRadius: 6,
        boxShadow: "0px 4px 35px 0px rgba(2, 67, 148, 0.08)",
        mb: 3,
      }}>
      <Box sx={{ position: "absolute", m: 2 }}>
        <IconButton
          sx={{ color: "white", width: 32, height: 32 }}
          onClick={handleFavoriteClick}
          aria-label="favorite">
          {isFavorite ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
        </IconButton>
      </Box>
      <CardMedia
        sx={{ height: 170, borderRadius: 6 }}
        image={item.image ? `${process.env.REACT_APP_BACKEND_BASE_URL}${item.image}` : "No image available"}
        title={item.name}
      />
      <CardContent sx={{ pt: 0 }}>
        <Box sx={{ p: "11px 0" }}>
          <Typography
            sx={{ fontSize: "20px", fontWeight: 600, pt: 1 }}
            variant="subtitle1"
            align="center">
            {item.name}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                }}
                variant="caption"
                align="center">
                Стартовая цена:
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
                variant="caption"
                align="center">
                {item.startingPrice} тг
              </Typography>
            </Box>
            {type === "nearest" ? (
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                  }}
                  variant="caption"
                  align="center">
                  Начнется через
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                  variant="caption"
                  align="center">
                  {timeUntilStart}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                  }}
                  variant="caption"
                  align="center">
                  Текущая ставка
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                  variant="caption"
                  align="center">
                  {item.currentPrice} тг
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        {type !== "nearest" && (
          <Button
            fullWidth
            sx={{
              background:
                "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
              height: "32px",
              boxShadow: "0px 4px 35px 0px rgba(2, 67, 148, 0.08)",
              borderRadius: 1.5,
              mt: 1,
            }}
            variant="contained"
            onClick={handleParticipateClick}>
            {item.status === "completed" ? "Посмотреть" : "Участвовать"}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default CardItem;
