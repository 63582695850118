import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import { AuctionItemService } from "../../services/AuctionItemService";
import { UserService } from "../../services/UserService";
import { styled } from "@mui/system";

// Стилизация ячеек
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  padding: "10px 16px",
  borderBottom: "1px solid #E3E3E3",
}));

// Стилизация строк
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    borderBottom: "1px solid #E3EEFF)",
  },
  "&:nth-of-type(even)": {
    borderBottom: "1px solid #E3EEFF",
    backgroundColor: "var(--Silver, #F5F7FA)",
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 600,
  marginBottom: theme.spacing(1),
}));

const UserStatsTable = ({ auctionId }) => {
  const [users, setUsers] = useState([]);
  const [topHighBids, setTopHighBids] = useState([]);
  const [topActiveUsers, setTopActiveUsers] = useState([]);
  const [buyer, setBuyer] = useState(null);
  const [buyerBidCount, setBuyerBidCount] = useState(0);
  const [buyerLastBid, setBuyerLastBid] = useState(0);
  const [buyerPhone, setBuyerPhone] = useState("Нет телефона");
  const [isBuyerLoaded, setIsBuyerLoaded] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const bidders = await AuctionItemService.fetchTopBidders(auctionId);
        const usersInfo = await Promise.all(
          bidders.map(async (bidder) => {
            const userDetails = await UserService.fetchUserById(bidder.user._id);
            return {
              ...bidder,
              username: userDetails.firstName || "No Name",
              email: userDetails.email || "No Email",
              phone: userDetails.phone || "No Phone",
              bidCount: bidder.bidCount || 0,
              lastBidAmount: bidder.lastBidAmount || 0,
            };
          }),
        );

        const sortedByBidAmount = [...usersInfo]
          .sort((a, b) => b.lastBidAmount - a.lastBidAmount)
          .slice(0, 10);
        const sortedByBidCount = [...usersInfo]
          .sort((a, b) => b.bidCount - a.bidCount)
          .slice(0, 10);
        const auctionItem = await AuctionItemService.fetchAuctionItemById(auctionId);

        if (auctionItem && auctionItem.highestBid && auctionItem.highestBid.user) {
          const buyerInfo = auctionItem.highestBid.user;
          const buyerBidInfo = bidders.find((bidder) => bidder.user._id === buyerInfo._id);

          setBuyer(buyerInfo);
          setBuyerBidCount(buyerBidInfo ? buyerBidInfo.bidCount : 0);
          setBuyerLastBid(buyerBidInfo ? buyerBidInfo.lastBidAmount : 0);

          const buyerDetails = await UserService.fetchUserById(buyerInfo._id);
          setBuyerPhone(buyerDetails.phone || "Нет телефона");
        } else {
          console.log("Покупатель отсутствует.");
        }

        setUsers(usersInfo);
        setTopHighBids(sortedByBidAmount);
        setTopActiveUsers(sortedByBidCount);
        setIsBuyerLoaded(true);
      } catch (error) {
        console.error("Ошибка при получении данных о пользователях:", error);
      }
    };
    fetchUsers();
  }, [auctionId]);

  return (
    <Box>
      <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
        Участники
      </Typography>

      <SectionTitle>Покупатель</SectionTitle>
      <TableContainer component={Paper} sx={{ mb: 4, boxShadow: "none", border: "none" }}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#FFF" }}>
            <StyledTableRow>
              <StyledTableCell>№</StyledTableCell>
              <StyledTableCell>Пользователь</StyledTableCell>
              <StyledTableCell>Количество ставок</StyledTableCell>
              <StyledTableCell>Ставка</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Телефон</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {buyer ? (
              <StyledTableRow>
                <StyledTableCell>1</StyledTableCell>
                <StyledTableCell>
                  {buyer.firstName} {buyer.lastName}
                </StyledTableCell>
                <StyledTableCell>{buyerBidCount}</StyledTableCell>
                <StyledTableCell>
                  {buyerLastBid ? `${buyerLastBid} ₽` : "Нет данных"}
                </StyledTableCell>
                <StyledTableCell>{buyer.email}</StyledTableCell>
                <StyledTableCell sx={{ textDecoration: "underline", color: "#3083FF" }}>
                  {buyerPhone}
                </StyledTableCell>
              </StyledTableRow>
            ) : isBuyerLoaded ? (
              <StyledTableRow>
                <StyledTableCell colSpan={6} align="center" sx={{ color: "red" }}>
                  Нет покупателя
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={6} align="center">
                  Загрузка...
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <SectionTitle>ТОП 10 высоких ставок</SectionTitle>
      <TableContainer component={Paper} sx={{ mb: 4, boxShadow: "none", border: "none" }}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#FFF" }}>
            <StyledTableRow>
              <StyledTableCell>№</StyledTableCell>
              <StyledTableCell>Пользователь</StyledTableCell>
              <StyledTableCell>Количество ставок</StyledTableCell>
              <StyledTableCell>Ставка</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Телефон</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {topHighBids.map((user, index) => (
              <StyledTableRow key={user.user._id}>
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{user.username}</StyledTableCell>
                <StyledTableCell>{user.bidCount}</StyledTableCell>
                <StyledTableCell>{user.lastBidAmount} ₽</StyledTableCell>
                <StyledTableCell>{user.email}</StyledTableCell>
                <StyledTableCell sx={{ textDecoration: "underline", color: "#3083FF" }}>
                  {user.phone}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <SectionTitle>ТОП 10 активных пользователей</SectionTitle>
      <TableContainer component={Paper} sx={{ boxShadow: "none", border: "none" }}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#FFF" }}>
            <StyledTableRow>
              <StyledTableCell>№</StyledTableCell>
              <StyledTableCell>Пользователь</StyledTableCell>
              <StyledTableCell>Количество ставок</StyledTableCell>
              <StyledTableCell>Ставка</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Телефон</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {topActiveUsers.map((user, index) => (
              <StyledTableRow key={user.user._id}>
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{user.username}</StyledTableCell>
                <StyledTableCell>{user.bidCount}</StyledTableCell>
                <StyledTableCell>{user.lastBidAmount} ₽</StyledTableCell>
                <StyledTableCell>{user.email}</StyledTableCell>
                <StyledTableCell sx={{ textDecoration: "underline", color: "#3083FF" }}>
                  {user.phone}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UserStatsTable;
