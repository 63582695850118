// App.js
import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RouterBuilder from "./App.router";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { AuthProvider, useAuth } from "./Context/AuthContext";
import { NotificationsProvider } from "./Context/NotificationsContext";
import { NotificationProvider } from "./Context/NotificationContext";
import { SocketProvider } from "./Context/SocketContext";

const AppContent = () => {
  const { loading } = useAuth();

  if (loading) {
    return <p>Loading...</p>;
  }

  const routes = RouterBuilder();

  return (
    <NotificationsProvider>
      <SocketProvider>
        <NotificationProvider>
          <RouterProvider router={createBrowserRouter(routes)}>
            <ScrollToTop />
          </RouterProvider>
        </NotificationProvider>
      </SocketProvider>
    </NotificationsProvider>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
};

export default App;
