import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Link,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useAuth } from "../../Context/AuthContext";
import { useNotification } from "../../Context/NotificationContext";

const Login = () => {
  const { t } = useTranslation();
  const { login } = useAuth();
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!validateEmail(email)) {
      setError("Некорректный email. Пожалуйста, введите правильный адрес электронной почты.");
      return;
    }
    navigate("/");
    try {
      await login(email, password);
      setIsAuthenticated(true);
      showNotification("Вы успешно авторизованы!", "success");
    } catch (error) {
      console.error("Ошибка при входе:", error);
      setError("Неверный email или пароль. Пожалуйста, проверьте свои данные и попробуйте снова.");
    }
  };

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        maxWidth: "460px",
        margin: "0 auto",
        my: 5,
      }}>
      <Typography variant="h4" sx={{ fontSize: "36px", mb: 4, fontWeight: 600 }}>
        {t("login.title")}
      </Typography>
      {error && (
        <Typography variant="body2" sx={{ color: "red", my: 3 }}>
          {error}
        </Typography>
      )}
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
          {t("login.emailOrUsername")}
        </Typography>
        <TextField
          label={t("login.emailOrUsername")}
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              background: "#FFFFFF",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#89939E",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              fontWeight: 400,
              transform: "translate(14px, 12px) scale(1)",
            },
            "& .MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              fontWeight: 400,
              padding: "12px",
            },
          }}
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
          {t("login.password")}
        </Typography>
        <TextField
          label={t("login.password")}
          variant="outlined"
          fullWidth
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{
            mb: 1,
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              background: "#FFFFFF",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#89939E",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              fontWeight: 400,
              transform: "translate(14px, 12px) scale(1)",
            },
            "& .MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              fontWeight: 400,
              padding: "12px",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={t("login.togglePasswordVisibility")}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end">
                  {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Link
            component={RouterLink}
            to="/password-recovery"
            href="#"
            sx={{
              fontSize: "12px",
              color: "#0072ff",
              textDecoration: "none",
            }}>
            {t("login.forgotPassword")}
          </Link>
        </Box>
      </Box>

      <Button
        variant="contained"
        fullWidth
        onClick={handleSubmit}
        sx={{
          my: 3,
          background:
            "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
          boxShadow: "none",
          borderRadius: "10px",
          py: "12px",
          fontSize: "16px",
          fontWeight: 400,
          textTransform: "none",
        }}>
        {t("login.login")}
      </Button>
      <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: 400 }}>
        {t("login.noAccount")}
        <Link
          component={RouterLink}
          to="/register"
          href="#"
          sx={{
            ml: 3,
            fontSize: "14px",
            fontWeight: 400,
            color: "#0671E0",
            textDecoration: "none",
          }}>
          {t("login.register")}
        </Link>
      </Typography>
    </Box>
  );
};

export default Login;
