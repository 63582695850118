import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  MenuItem,
  TextField,
  Button,
  Checkbox,
  Paper,
  Select,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { AuctionItemService } from "../../services/AuctionItemService";
import { fetchCategories } from "../../services/CategoryService";

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
    "&:hover": {
      border: "1px solid var(--blue-btn, #3083FF)",
    },
  },
});

const textFieldStyles = {
  pr: 1,
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    borderColor: "#89939E",
    "& fieldset": {
      borderColor: "#89939E",
    },
  },
  "& .MuiInputBase-input": {
    py: 1.5,
    fontSize: "12px",
  },
  width: "100%",
};


const dateOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit'
};

const LotsAdmin = () => {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchParams, setSearchParams] = useState({
    id: "",
    product: "",
    category: "",
    startDate: "",
    endDate: "",
    startPrice: "",
    increment: "",
    status: "",
  });

  const navigate = useNavigate();
  const [action, setAction] = useState("");
  const [lots, setLots] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedCategories = await fetchCategories();
        setCategories(fetchedCategories);

        const data = await AuctionItemService.fetchAuctionItems();
        setLots(data);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };
    fetchData();
  }, []);

  const getCategoryName = (categoryId) => {
    const category = categories.find((cat) => cat._id === categoryId);
    return category ? category.name : "Неизвестная категория";
  };

  const handleRowClick = (lot) => {
    navigate(`/admin/lots/${lot._id}`); // Переход на страницу редактирования лота
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchParams({
      ...searchParams,
      [event.target.name]: event.target.value,
    });
  };

  // Логика фильтрации лотов
  const filteredLots = lots.filter((lot) => {
    return (
      (!searchParams.id || lot._id.toLowerCase().includes(searchParams.id.toLowerCase())) &&
      (!searchParams.product ||
        lot.name.toLowerCase().includes(searchParams.product.toLowerCase())) &&
      (!searchParams.category ||
        getCategoryName(lot.category)
          .toLowerCase()
          .includes(searchParams.category.toLowerCase())) &&
      (!searchParams.startDate ||
        lot.startDate.toLowerCase().includes(searchParams.startDate.toLowerCase())) &&
      (!searchParams.endDate ||
        lot.endDate.toLowerCase().includes(searchParams.endDate.toLowerCase())) &&
      (!searchParams.startPrice ||
        lot.startingPrice.toString().includes(searchParams.startPrice)) &&
      (!searchParams.increment || lot.increment.toString().includes(searchParams.increment)) &&
      (!searchParams.status || lot.status.toLowerCase().includes(searchParams.status.toLowerCase()))
    );
  });

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedLots = filteredLots.slice(startIndex, endIndex);

  return (
    <Paper sx={{ borderRadius: "10px", p: 3, boxShadow: "none" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, mb: 2 }}>
          Лоты
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/admin/add-lot")}
          sx={{
            background:
              "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
            boxShadow: "none",
            borderRadius: "10px",
            p: "14px 34px",
            fontSize: "14px",
            fontWeight: 400,
            textTransform: "none",
            mb: 4,
          }}>
          Добавить товар
        </Button>
      </Box>

      <Table sx={{ minWidth: 650, mb: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                p: 0,
                borderBottom: "1px solid #ABBED1",
                width: 50,
              }}>
              <Checkbox sx={{ pt: 4 }} />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 140,
              }}>
              ID
              <TextField
                name="id"
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                value={searchParams.id}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Товар
              <TextField
                name="product"
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                value={searchParams.product}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Категория
              <TextField
                name="category"
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                value={searchParams.category}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Дата начала
              <TextField
                name="startDate"
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                value={searchParams.startDate}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Дата завершения
              <TextField
                name="endDate"
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                value={searchParams.endDate}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Стартовая цена
              <TextField
                name="startPrice"
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                value={searchParams.startPrice}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Шаг увеличения
              <TextField
                name="increment"
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                value={searchParams.increment}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Статус
              <TextField
                name="status"
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                value={searchParams.status}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedLots.map((lot, index) => (
            <TableRow
              key={index}
              sx={{ backgroundColor: index % 2 === 0 ? "transparent" : "#F5F7FA" }}
              onClick={() => handleRowClick(lot)}
              style={{ cursor: "pointer" }}>
              <TableCell sx={{ borderBottom: "none", p: 0 }}>
                <Checkbox />
              </TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>{lot._id}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>{lot.name}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>
                {getCategoryName(lot.category)}
              </TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>{new Date(lot.startDate).toLocaleString("ru", dateOptions)}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>{new Date(lot.endDate).toLocaleString("ru", dateOptions)}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>
                {lot.startingPrice}
              </TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>{lot.increment}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>
                <Box
                  sx={{
                    backgroundColor:
                      lot.status === "completed"
                        ? "#D9F8E5"
                        : lot.status === "active"
                        ? "#FEEADD"
                        : "transparent",
                    borderRadius: "8px",
                    p: "5px 10px",
                    textAlign: "center",
                    width: "fit-content",
                  }}>
                  {lot.status}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>Количество элементов:</Typography>
          <FormControl variant="outlined" sx={{ ml: 2, minWidth: 60 }}>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              displayEmpty
              inputProps={{ "aria-label": "Items per page" }}
              sx={{ height: "35px", borderRadius: 2 }}>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <StyledPagination
          count={Math.ceil(filteredLots.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>
    </Paper>
  );
};

export default LotsAdmin;
