import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Grid, Button, Paper, Modal } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import GppBadOutlinedIcon from "@mui/icons-material/GppBadOutlined";
import axiosInstance from "../../axiosConfig";
import { useParams, useNavigate } from "react-router-dom";
import { emailRegex, phoneRegex } from "../../utils/regex";


const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    borderColor: "#89939E",
    "& fieldset": {
      borderColor: "#89939E",
    },
  },
  "& .MuiInputBase-input": {
    py: "10px",
  },
};

const UserDetail = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        console.log(`Fetching user data for user ID: ${userId}`);
        const response = await axiosInstance.get(`/api/users/${userId}`);
        console.log("User data fetched:", response.data);
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const validateFields = () => {
    let tempErrors = {};

    if (!userData.phone || !phoneRegex.test(userData.phone)) {
      tempErrors.phone = "Invalid phone number";
    }

    if (userData.email && !emailRegex.test(userData.email)) {
      tempErrors.email = "Invalid email address";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSave = async () => {
    if (validateFields()) {
      try {
        console.log("Updating user data:", userData);
        await axiosInstance.put(`/api/users/${userId}`, userData);
        alert("Данные успешно сохранены");
      } catch (error) {
        console.error("Ошибка при сохранении данных пользователя: ", error);
      }
    }
  };

  const handleVerify = async () => {
    try {
      console.log("Verifying user:", userData._id);
      const updatedData = { ...userData, isVerified: true, verificationRequest: false };
      await axiosInstance.put(`/api/users/${userId}`, updatedData);
      setUserData(updatedData);
      handleCloseModal(true);
    } catch (error) {
      console.error("Error verifying user:", error);
    }
  };

  const handleReject = async () => {
    try {
      console.log("Rejecting verification for user:", userData._id);
      const updatedData = {
        ...userData,
        isVerified: false,
        verificationRequest: false,
        verificationDocument: null,
      };
      await axiosInstance.put(`/api/users/${userId}`, updatedData);
      setUserData(updatedData);
    } catch (error) {
      console.error("Error rejecting verification:", error);
    }
  };

  const dateOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  return (
    <Paper sx={{ borderRadius: "10px", p: 3, boxShadow: "none" }}>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
        sx={{
          borderRadius: "8px",
          textTransform: "none",
          color: "#3083FF",
          borderColor: "#3083FF",
          fontWeight: 400,
          mb: 3,
          "&:hover": {
            borderColor: "#3083FF",
            backgroundColor: "rgba(48, 131, 255, 0.08)",
          },
        }}>
        Назад
      </Button>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, mb: 3 }}>
        Данные о пользователе
      </Typography>
      <Typography variant="h6" sx={{ fontSize: "20px", fontWeight: 600, mb: 2 }}>
        Персональная информация
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Email</Typography>
          <TextField
            value={userData.email || ""}
            name="email"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            InputProps={{ readOnly: true }}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Телефон</Typography>
          <TextField
            value={userData.phone || ""}
            name="phone"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
            error={!!errors.phone}
            helperText={errors.phone}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Фамилия</Typography>
          <TextField
            value={userData.lastName || ""}
            name="lastName"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Имя</Typography>
          <TextField
            value={userData.firstName || ""}
            name="firstName"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Отчество</Typography>
          <TextField
            value={userData.middleName || ""}
            name="middleName"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Дата рождения</Typography>
          <TextField
            value={new Date(userData.dob).toLocaleString('ru', dateOptions) || ""}
            name="dob"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Typography variant="h6" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
        Адрес
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Страна</Typography>
          <TextField
            value={userData.country || ""}
            name="country"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Город</Typography>
          <TextField
            value={userData.city || ""}
            name="city"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Улица</Typography>
          <TextField
            value={userData.street || ""}
            name="street"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Номер дома</Typography>
          <TextField
            value={userData.houseNumber || ""}
            name="houseNumber"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Номер квартиры</Typography>
          <TextField
            value={userData.apartment || ""}
            name="apartment"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Typography variant="h6" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
        Верификация
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
        {userData.isVerified ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              backgroundColor: "#e3f2fd",
              padding: 1,
              pr: 2,
              borderRadius: "10px",
              mb: 3,
              maxWidth: "fit-content",
            }}>
            <GppGoodOutlinedIcon sx={{ color: "#3083FF" }} />
            <Typography sx={{ color: "#000", fontSize: "14px", fontWeight: "400" }}>
              Верифицирован
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              backgroundColor: "#ffebee",
              padding: 1,
              pr: 2,
              borderRadius: "10px",
              mb: 3,
              maxWidth: "fit-content",
            }}>
            <GppBadOutlinedIcon sx={{ color: "#d32f2f" }} />
            <Typography sx={{ color: "#d32f2f", fontSize: "14px", fontWeight: "400" }}>
              Не Верифицирован
            </Typography>
          </Box>
        )}
        {userData.verificationRequest && (
          <Button
            variant="contained"
            onClick={handleOpenModal}
            sx={{
              background:
                "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
              boxShadow: "none",
              borderRadius: "10px",
              py: "8px",
              px: "16px",
              fontSize: "14px",
              fontWeight: 400,
              textTransform: "none",
              mb: 3,
            }}>
            Просмотр документа
          </Button>
        )}
      </Box>
      <Modal open={open} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}>
          <img
            src={`${process.env.REACT_APP_BACKEND_BASE_URL}uploads/${userData.verificationDocument}`}
            alt="Verification Document"
            style={{ maxWidth: "100%" }}
          />
          <Box sx={{ mt: 2, display: "flex", justifyContent: "space-around" }}>
            <Button variant="contained" color="success" onClick={handleVerify}>
              Подтвердить
            </Button>
            <Button variant="contained" color="error" onClick={handleReject}>
              Отклонить
            </Button>
          </Box>
        </Box>
      </Modal>
      <Button
        variant="contained"
        onClick={handleSave}
        sx={{
          background:
            "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
          boxShadow: "none",
          borderRadius: "10px",
          p: "12px 32px",
          fontSize: "16px",
          fontWeight: 400,
          textTransform: "none",
        }}>
        Сохранить
      </Button>
    </Paper>
  );
};

export default UserDetail;
