import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Button, Stepper, Step, StepLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { dobRegex } from "../../utils/regex";
import { useAuth } from "../../Context/AuthContext";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

const PersonalInfo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [dob, setDob] = useState(null);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [apartment, setApartment] = useState("");
  const [errors, setErrors] = useState({});
  const { user, setUser } = useAuth();

  useEffect(() => {
    const storedPersonalInfo = JSON.parse(localStorage.getItem("personalInfoData")) || {};
    setLastName(storedPersonalInfo.lastName || "");
    setFirstName(storedPersonalInfo.firstName || "");
    setDob(storedPersonalInfo.dob || null);
    setCountry(storedPersonalInfo.country || "");
    setCity(storedPersonalInfo.city || "");
    setStreet(storedPersonalInfo.street || "");
    setHouseNumber(storedPersonalInfo.houseNumber || "");
    setApartment(storedPersonalInfo.apartment || "");
  }, []);

  const validateFields = () => {
    const newErrors = {};

    if (!lastName.trim()) newErrors.lastName = "Фамилия обязательна";
    if (!firstName.trim()) newErrors.firstName = "Имя обязательно";
    //if (!dob.trim()) newErrors.dob = "Дата рождения обязательна";
    //else if (!dobRegex.test(dob)) newErrors.dob = "Неверный формат даты (ДД/ММ/ГГГГ)";
    if (!country.trim()) newErrors.country = "Страна обязательна";
    if (!city.trim()) newErrors.city = "Город обязателен";
    if (!street.trim()) newErrors.street = "Улица обязательна";
    if (!houseNumber.trim()) newErrors.houseNumber = "Номер дома обязателен";
    if (!apartment.trim()) newErrors.apartment = "Квартира обязательна";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextStep = () => {
    if (validateFields()) {
      const personalInfoData = {
        lastName,
        firstName,
        dob,
        country,
        city,
        street,
        houseNumber,
        apartment,
      };

      localStorage.setItem("personalInfoData", JSON.stringify(personalInfoData));

      const updatedUser = {
        ...user,
        lastName,
        firstName,
        dob,
        country,
        city,
        street,
        houseNumber,
        apartment,
      };

      setUser(updatedUser);
      localStorage.setItem("user", JSON.stringify(updatedUser));

      navigate("/register/verification");
    }
  };

  const handleSkip = () => {
    const personalInfoData = {
      lastName: "",
      firstName: "",
      dob: "",
      country: "",
      city: "",
      street: "",
      houseNumber: "",
      apartment: "",
    };

    localStorage.setItem("personalInfoData", JSON.stringify(personalInfoData));
    navigate("/register/verification");
  };

  // const handleDobChange = (event) => {
  //    let value = event.target.value;
  //   // value = value.replace(/\D/g, "");

  //   // if (value.length > 4) {
  //   //   value = value.replace(/(\d{2})(\d{2})(\d{0,4})/, "$1/$2/$3");
  //   // } else if (value.length > 2) {
  //   //   value = value.replace(/(\d{2})(\d{0,2})/, "$1/$2");
  //   // }

  //   setDob(value);
  // };

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        maxWidth: "460px",
        margin: "0 auto",
        my: 5,
      }}>
      <Stepper
        activeStep={1}
        alternativeLabel
        sx={{
          width: "80%",
          "& .MuiStep-root": {
            p: 0,
          },
          "& .MuiStepLabel-root .MuiStepLabel-label": {
            display: "none",
          },
          "& .MuiStepConnector-root": {
            top: "21px",
            left: "calc(-50% + 22px)",
            right: "calc(50% + 20px)",
          },
          "& .MuiStepIcon-root": {
            width: "44px",
            height: "44px",
            color: "#f0f2f5",
            borderRadius: "50%",
            border: "2px solid #ABBED1",
            background: "none",
            "&.Mui-active": {
              color: "transparent",
              background:
                "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
              border: "none",
            },
            "&.Mui-active .MuiStepIcon-text": {
              fill: "#fff",
            },
          },
          "& .MuiStepConnector-line": {
            borderColor: "#ABBED1",
            borderTopWidth: "2px",
            top: "50%",
          },
          "& .MuiStepIcon-text": {
            fill: "#ABBED1",
            fontSize: "12px",
            fontWeight: 600,
          },
        }}>
        {[1, 2, 3].map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Typography variant="h4" sx={{ fontSize: "36px", my: 4, fontWeight: 600 }}>
        {t("personalInfo.title")}
      </Typography>
      <Typography variant="body1" sx={{ fontSize: "14px", mb: 4, fontWeight: 400 }}>
        {t("personalInfo.step")}
      </Typography>
      <Box sx={{ width: "100%" }}>
        {errors.general && (
          <Typography color="error" sx={{ mb: 2 }}>
            {errors.general}
          </Typography>
        )}
        <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
          {t("personalInfo.lastName")}
        </Typography>
        <TextField
          label={t("personalInfo.lastName")}
          variant="outlined"
          fullWidth
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          error={!!errors.lastName}
          helperText={errors.lastName}
          sx={{
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              background: "#FFFFFF",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#89939E",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              fontWeight: 400,
              transform: "translate(14px, 12px) scale(1)",
            },
            "& .MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              fontWeight: 400,
              padding: "12px",
            },
          }}
        />
        <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
          {t("personalInfo.firstName")}
        </Typography>
        <TextField
          label={t("personalInfo.firstName")}
          variant="outlined"
          fullWidth
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          error={!!errors.firstName}
          helperText={errors.firstName}
          sx={{
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              background: "#FFFFFF",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#89939E",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              fontWeight: 400,
              transform: "translate(14px, 12px) scale(1)",
            },
            "& .MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              fontWeight: 400,
              padding: "12px",
            },
          }}
        />
        <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
          {t("personalInfo.dob")}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <DatePicker
          label={t("personalInfo.dob")}
          variant="outlined"
          fullWidth
          value={dob ? dayjs(dob) : null}
          //onChange={handleDobChange}
          //value={dayjs(dob)} 
          onChange={(newValue) => {setDob(newValue)}}
          //error={!!errors.dob}
          //helperText={errors.dob}
          placeholder="ДД.ММ.ГГГГ"
          sx={{
            width: "100%",
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              background: "#FFFFFF",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#89939E",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              fontWeight: 400,
              transform: "translate(14px, 12px) scale(1)",
            },
            "& .MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              fontWeight: 400,
              padding: "12px",
            },
          }}
          /></LocalizationProvider>
        <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
          {t("personalInfo.country")}
        </Typography>
        <TextField
          label={t("personalInfo.country")}
          variant="outlined"
          fullWidth
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          error={!!errors.country}
          helperText={errors.country}
          sx={{
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              background: "#FFFFFF",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#89939E",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              fontWeight: 400,
              transform: "translate(14px, 12px) scale(1)",
            },
            "& .MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              fontWeight: 400,
              padding: "12px",
            },
          }}
        />
        <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
          {t("personalInfo.city")}
        </Typography>
        <TextField
          label={t("personalInfo.city")}
          variant="outlined"
          fullWidth
          value={city}
          onChange={(e) => setCity(e.target.value)}
          error={!!errors.city}
          helperText={errors.city}
          sx={{
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              background: "#FFFFFF",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#89939E",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              fontWeight: 400,
              transform: "translate(14px, 12px) scale(1)",
            },
            "& .MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              fontWeight: 400,
              padding: "12px",
            },
          }}
        />
        <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
          {t("personalInfo.street")}
        </Typography>
        <TextField
          label={t("personalInfo.street")}
          variant="outlined"
          fullWidth
          value={street}
          onChange={(e) => setStreet(e.target.value)}
          error={!!errors.street}
          helperText={errors.street}
          sx={{
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              background: "#FFFFFF",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#89939E",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              fontWeight: 400,
              transform: "translate(14px, 12px) scale(1)",
            },
            "& .MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              fontWeight: 400,
              padding: "12px",
            },
          }}
        />
        <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
          {t("personalInfo.houseNumber")}
        </Typography>
        <TextField
          label={t("personalInfo.houseNumber")}
          variant="outlined"
          fullWidth
          value={houseNumber}
          onChange={(e) => setHouseNumber(e.target.value)}
          error={!!errors.houseNumber}
          helperText={errors.houseNumber}
          sx={{
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              background: "#FFFFFF",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#89939E",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              fontWeight: 400,
              transform: "translate(14px, 12px) scale(1)",
            },
            "& .MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              fontWeight: 400,
              padding: "12px",
            },
          }}
        />
        <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
          {t("personalInfo.apartment")}
        </Typography>
        <TextField
          label={t("personalInfo.apartment")}
          variant="outlined"
          fullWidth
          value={apartment}
          onChange={(e) => setApartment(e.target.value)}
          error={!!errors.apartment}
          helperText={errors.apartment}
          sx={{
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              background: "#FFFFFF",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#89939E",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              fontWeight: 400,
              transform: "translate(14px, 12px) scale(1)",
            },
            "& .MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              fontWeight: 400,
              padding: "12px",
            },
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Button
            variant="outlined"
            onClick={handleSkip}
            sx={{
              width: "45%",
              borderColor: "#3083FF",
              color: "#3083FF",
              py: "14px",
              fontSize: "16px",
              fontWeight: 400,
              textTransform: "none",
              borderRadius: "10px",
            }}>
            {t("personalInfo.skip")}
          </Button>
          <Button
            variant="contained"
            onClick={handleNextStep}
            sx={{
              width: "45%",
              background:
                "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
              boxShadow: "none",
              borderRadius: "10px",
              py: "14px",
              fontSize: "16px",
              fontWeight: 400,
              textTransform: "none",
            }}>
            {t("personalInfo.continue")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalInfo;
