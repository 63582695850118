import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Link,
  FormControlLabel,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import InputMask from "react-input-mask";
import { emailRegex, passwordRegex, phoneRegex } from "../../utils/regex";
import { useAuth } from "../../Context/AuthContext";

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const { setUser } = useAuth();

  const referralCode = new URLSearchParams(location.search).get("ref");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateEmail = (email) => {
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    return phoneRegex.test(phone);
  };

  const validatePassword = (password) => {
    return passwordRegex.test(password);
  };

  const handleRegister = () => {
    if (!validateEmail(email)) {
      setError("Некорректный email. Убедитесь, что вы ввели правильный адрес электронной почты.");
      return;
    }
    if (!validatePhone(phone)) {
      setError("Некорректный номер телефона. Введите правильный номер.");
      return;
    }
    if (!validatePassword(password)) {
      setError(
        "Пароль должен содержать не менее 8 символов, включая одну заглавную букву, одну строчную букву и одну цифру.",
      );
      return;
    }
    if (password !== confirmPassword) {
      setError("Пароли не совпадают. Пожалуйста, введите одинаковые пароли.");
      return;
    }
    if (!acceptedTerms) {
      setError("Вы должны принять условия и политику конфиденциальности.");
      return;
    }

    const registrationData = { email, phone, password, referralCode };
    localStorage.setItem("registrationData", JSON.stringify(registrationData));
    setUser(registrationData);
    console.log("Регистрационные данные сохранены:", registrationData);
    navigate("/register/personal-info");
  };

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        maxWidth: "460px",
        margin: "0 auto",
        my: 5,
      }}>
      <Stepper
        activeStep={0}
        alternativeLabel
        sx={{
          width: "80%",
          "& .MuiStep-root": {
            p: 0,
          },
          "& .MuiStepLabel-root .MuiStepLabel-label": {
            display: "none",
          },
          "& .MuiStepConnector-root": {
            top: "21px",
            left: "calc(-50% + 22px)",
            right: "calc(50% + 20px)",
          },
          "& .MuiStepIcon-root": {
            width: "44px",
            height: "44px",
            color: "#f0f2f5",
            borderRadius: "50%",
            border: "2px solid #ABBED1",
            background: "none",
            "&.Mui-active": {
              color: "transparent",
              background:
                "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
              border: "none",
            },
            "&.Mui-active .MuiStepIcon-text": {
              fill: "#fff",
            },
          },
          "& .MuiStepConnector-line": {
            borderColor: "#ABBED1",
            borderTopWidth: "2px",
            top: "50%",
          },
          "& .MuiStepIcon-text": {
            fill: "#ABBED1",
            fontSize: "12px",
            fontWeight: 600,
          },
        }}>
        {[1, 2, 3].map((label) => (
          <Step key={label}>
            <StepLabel StepIconProps={{ children: <Typography>{label}</Typography> }}></StepLabel>
          </Step>
        ))}
      </Stepper>
      <Typography variant="h4" sx={{ fontSize: "36px", my: 4, fontWeight: 600 }}>
        {t("register.title")}
      </Typography>
      <Typography variant="body1" sx={{ fontSize: "14px", mb: 4, fontWeight: 400 }}>
        {t("register.step1")}
      </Typography>
      {error && (
        <Typography color="error" mb={3} textAlign={"center"}>
          {error}
        </Typography>
      )}
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
          {t("register.email")}
        </Typography>
        <TextField
          label={t("register.email")}
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              background: "#FFFFFF",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#89939E",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              fontWeight: 400,
              transform: "translate(14px, 12px) scale(1)",
            },
            "& .MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              fontWeight: 400,
              padding: "12px",
            },
          }}
        />
        <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
          {t("register.phone")}
        </Typography>
        <InputMask
          mask="+7\ (999) 999-99-99"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          maskChar=" ">
          {() => (
            <TextField
              label={t("register.phone")}
              variant="outlined"
              fullWidth
              sx={{
                marginBottom: "16px",
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                  background: "#FFFFFF",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#89939E",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "14px",
                  fontWeight: 400,
                  transform: "translate(14px, 12px) scale(1)",
                },
                "& .MuiInputLabel-shrink": {
                  transform: "translate(14px, -6px) scale(0.75)",
                },
                "& .MuiInputBase-input": {
                  fontSize: "14px",
                  fontWeight: 400,
                  padding: "12px",
                },
              }}
            />
          )}
        </InputMask>
        <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
          {t("register.password")}
        </Typography>
        <TextField
          label={t("register.password")}
          variant="outlined"
          fullWidth
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              background: "#FFFFFF",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#89939E",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              fontWeight: 400,
              transform: "translate(14px, 12px) scale(1)",
            },
            "& .MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              fontWeight: 400,
              padding: "12px",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={t("register.togglePasswordVisibility")}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end">
                  {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
          {t("register.confirmPassword")}
        </Typography>
        <TextField
          label={t("register.confirmPassword")}
          variant="outlined"
          fullWidth
          type={showConfirmPassword ? "text" : "password"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          sx={{
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              background: "#FFFFFF",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#89939E",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              fontWeight: 400,
              transform: "translate(14px, 12px) scale(1)",
            },
            "& .MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              fontWeight: 400,
              padding: "12px",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={t("register.togglePasswordVisibility")}
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end">
                  {showConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptedTerms}
              onChange={(e) => setAcceptedTerms(e.target.checked)}
              sx={{ mt: "-9px" }}
            />
          }
          label={
            <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
              {t("register.agreeToTerms")}
              <Box component="span" display="block">
                <Link href="/privacy-policy" sx={{ color: "#0671E0", textDecoration: "none" }}>
                  {t("register.privacyPolicy")}
                </Link>{" "}
                {t("register.and")}{" "}
                <Link href="/terms" sx={{ color: "#0671E0", textDecoration: "none" }}>
                  {t("register.termsOfUse")}
                </Link>
              </Box>
            </Typography>
          }
          sx={{
            alignItems: "flex-start",
            "& .MuiFormControlLabel-label": {
              mt: "-6px",
            },
          }}
        />
        <Button
          variant="contained"
          fullWidth
          onClick={handleRegister}
          sx={{
            my: 3,
            background:
              "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
            boxShadow: "none",
            borderRadius: "10px",
            py: "12px",
            fontSize: "16px",
            fontWeight: 400,
            textTransform: "none",
          }}>
          {t("register.registerButton")}
        </Button>
      </Box>
    </Box>
  );
};

export default Register;
