import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  MenuItem,
  TextField,
  Paper,
  Select,
  FormControl,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import OrderDetail from "./OrderDetail";
import { AuctionItemService } from "../../services/AuctionItemService";

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
    "&:hover": {
      border: "1px solid var(--blue-btn, #3083FF)",
    },
  },
});

const textFieldStyles = {
  pr: 1,
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    borderColor: "#89939E",
    "& fieldset": {
      borderColor: "#89939E",
    },
  },
  "& .MuiInputBase-input": {
    py: 1.5,
    fontSize: "12px",
  },
  width: "100%",
};

const ITEMS_PER_PAGE_OPTIONS = [5, 10, 15];

const OrderAdmin = () => {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [search, setSearch] = useState({
    lotNumber: "",
    product: "",
    date: "",
    cost: "",
    customer: "",
    status: "",
  });
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const loadAllOrders = async () => {
      try {
        setLoading(true);
        const ordersData = await AuctionItemService.fetchAllPurchases();
        console.log("Полученные заказы:", ordersData);
        setOrders(ordersData);
        setLoading(false);
      } catch (error) {
        console.error("Ошибка при загрузке заказов:", error);
        setError("Не удалось загрузить заказы.");
        setLoading(false);
      }
    };

    loadAllOrders();
  }, []);

  const getOrderValue = (order, key) => {
    switch (key) {
      case "lotNumber":
        return order.auctionId?.lot || "";
      case "product":
        return order.productData?.name || "";
      case "date":
        return new Date(order.date).toLocaleString("ru-RU", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
      case "cost":
        return String(order.productData?.price || 0) || "";
      case "customer":
        return `${order.buyer?.firstName} ${order.buyer?.lastName}` || "";
      case "status":
        return order.isPaid ? "Оплачен" : "Не оплачен";
      default:
        return "";
    }
  };

  const filteredOrders = orders.filter((order) =>
    Object.keys(search).every((key) =>
      getOrderValue(order, key).toLowerCase().includes(search[key].toLowerCase()),
    ),
  );

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedOrders = filteredOrders.slice(startIndex, endIndex);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setPage(1);
  };

  const handleRowClick = (order) => {
    setSelectedOrder(order);
  };

  const handleBack = () => {
    setSelectedOrder(null);
  };

  const handleSearchChange = (event) => {
    const { name, value } = event.target;
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
    setPage(1);
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error" sx={{ mt: 4, textAlign: "center" }}>
        {error}
      </Typography>
    );
  }

  if (selectedOrder) {
    return <OrderDetail orderId={selectedOrder._id} onBack={handleBack} />;
  }

  return (
    <Paper sx={{ borderRadius: "10px", p: 3, boxShadow: "none" }}>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, mb: 2 }}>
        Заказы
      </Typography>
      <Table sx={{ minWidth: 650, my: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 100,
              }}>
              № Лот
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="lotNumber"
                value={search.lotNumber}
                onChange={handleSearchChange}
              />
            </TableCell>

            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Товар
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="product"
                value={search.product}
                onChange={handleSearchChange}
              />
            </TableCell>

            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Дата
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="date"
                value={search.date}
                onChange={handleSearchChange}
              />
            </TableCell>

            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Стоимость
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="cost"
                value={search.cost}
                onChange={handleSearchChange}
              />
            </TableCell>

            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Покупатель
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="customer"
                value={search.customer}
                onChange={handleSearchChange}
              />
            </TableCell>

            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Статус
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="status"
                value={search.status}
                onChange={handleSearchChange}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedOrders.map((order, index) => (
            <TableRow
              key={order._id}
              sx={{ backgroundColor: index % 2 === 0 ? "transparent" : "#F5F7FA" }}
              onClick={() => handleRowClick(order)}
              style={{ cursor: "pointer" }}>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 1 }}>
                {order.auctionId?._id}
              </TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 1 }}>
                {order.productData?.name}
              </TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 1 }}>
                {new Date(order.date).toLocaleString("ru-RU", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 1 }}>
                {order.productData?.price} тг
              </TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 1 }}>
                {`${order.buyer?.firstName} ${order.buyer?.lastName}`}
              </TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 1 }}>
                <Box
                  sx={{
                    backgroundColor: order.isPaid ? "#D9F8E5" : "#FDE6E3",
                    borderRadius: "8px",
                    p: "5px 10px",
                    textAlign: "center",
                    width: "fit-content",
                  }}>
                  {order.isPaid ? "Оплачен" : "Не оплачен"}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>Количество элементов:</Typography>
          <FormControl variant="outlined" sx={{ ml: 2, minWidth: 60 }}>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              displayEmpty
              inputProps={{ "aria-label": "Items per page" }}
              sx={{ height: "35px", borderRadius: 2 }}>
              {ITEMS_PER_PAGE_OPTIONS.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <StyledPagination
          count={Math.ceil(filteredOrders.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
          sx={{ ml: 2 }}
        />
      </Box>
    </Paper>
  );
};

export default OrderAdmin;
