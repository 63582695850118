import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem, Typography, Badge, Divider } from "@mui/material";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useNotifications } from "../../Context/NotificationsContext";
import { useNavigate } from "react-router-dom";

const NotificationsMenu = () => {
  const { notifications, removeNotification, clearNotifications } = useNotifications();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleNotifOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotifClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (notif) => {
    navigate(notif.link);
    handleNotifClose();
  };

  const handleDeleteNotification = (e, notifId) => {
    e.stopPropagation();
    removeNotification(notifId);
  };

  const handleClearAllNotifications = () => {
    clearNotifications();
    handleNotifClose();
  };

  return (
    <Box>
      <IconButton
        onClick={handleNotifOpen}
        sx={{ color: "#3083FF", border: "1px solid #3083FF", borderRadius: 2, mr: "12px" }}>
        <Badge badgeContent={notifications.length} color="error">
          <NotificationsOutlinedIcon sx={{ fontSize: "16px" }} />
        </Badge>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleNotifClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: -5,
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
            borderRadius: 2,
            minWidth: 270,
            maxHeight: 300,
            overflow: "auto",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        {notifications.length === 0 ? (
          <MenuItem disabled>
            <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>Нет уведомлений</Typography>
          </MenuItem>
        ) : (
          <>
            {notifications.length >= 2 && (
              <>
                <MenuItem
                  onClick={handleClearAllNotifications}
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#ff0000",
                    justifyContent: "center",
                  }}>
                  Очистить все уведомления
                </MenuItem>
                <Divider />
              </>
            )}

            {notifications.map((notif) => (
              <MenuItem
                key={notif.id}
                sx={{
                  p: 1.5,
                  fontSize: "14px",
                  fontWeight: 400,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={() => handleNotificationClick(notif)}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    mr: 1,
                  }}>
                  {notif.message}
                </Typography>

                <IconButton
                  edge="end"
                  size="small"
                  onClick={(e) => handleDeleteNotification(e, notif.id)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </MenuItem>
            ))}
          </>
        )}
      </Menu>
    </Box>
  );
};

export default NotificationsMenu;
