import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Description({ description }) {
  const { t } = useTranslation();

  return (
    <Box sx={{ my: 12 }}>
      <Typography variant="h4" sx={{ marginBottom: 3, fontWeight: "bold" }}>
      {t("description.title")}
      </Typography>
      <Box sx={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: description }} />
    </Box>
  );
}
