import React, { useState } from "react";
import { Box, Typography, TextField, Button, InputAdornment, IconButton, } from "@mui/material";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../axiosConfig";
import { useLocation } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";


const ResetPassword = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [emailSent, setEmailSent] = useState(false);
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const token = new URLSearchParams(location.search).get("token");
  const userId = new URLSearchParams(location.search).get("id");
  const [showPassword, setShowPassword] = useState(false);


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handlePasswordRecovery = async () => {
    setEmailSent(true);
    try {
      const response = await axiosInstance.post(`/api/users/reset-password`, {
        token,
        userId,
        password
      });
      console.log(response)
      setEmailSent(true);
      setMessage(response.data.message)
    } catch (err) {
console.log(err)
    }
  };

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        maxWidth: "460px",
        margin: "0 auto",
        my: 5,
      }}>
      <Typography variant="h4" sx={{ fontSize: "36px", mb: 4, fontWeight: 600 }}>
        {t("passwordRecovery.setNewPassword")}
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
          {t("passwordRecovery.newPassword")}
        </Typography>
        <TextField
          label={t("passwordRecovery.newPassword")}
          variant="outlined"
          fullWidth
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              background: "#FFFFFF",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#89939E",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              fontWeight: 400,
              transform: "translate(14px, 12px) scale(1)",
            },
            "& .MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              fontWeight: 400,
              padding: "12px",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={t("login.togglePasswordVisibility")}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end">
                  {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {!emailSent ? (
          <Button
            variant="contained"
            fullWidth
            onClick={handlePasswordRecovery}
            sx={{
              my: 3,
              background:
                "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
              boxShadow: "none",
              borderRadius: "10px",
              py: "12px",
              fontSize: "16px",
              fontWeight: 400,
              textTransform: "none",
            }}>
            {t("passwordRecovery.saveNewPassword")}
          </Button>
        ) : (
          <Typography sx={{ px: 1, my: 3, fontSize: "14px", textAlign: "center" }}>
            {message}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ResetPassword;
