import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Grid, Typography, Box, styled, IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslation } from "react-i18next";
import { AuctionItemService } from "../../services/AuctionItemService";
import CardItem from "../CardItem/CardItem";

export default function HurryUpItemsSlider() {
  const { t } = useTranslation();
  const sliderRef = useRef();
  const navigate = useNavigate();
  const [auctionItems, setAuctionItems] = useState([]);

  useEffect(() => {
    const fetchAuctionItems = async () => {
      try {
        const items = await AuctionItemService.fetchAuctionItems();
        const activeItems = items.filter((item) => item.status === "active");
        setAuctionItems(activeItems);
      } catch (error) {
        console.error("Error fetching auction items:", error);
      }
    };

    fetchAuctionItems();
  }, []);

  const StyledIconButton = styled(IconButton)({
    "&::before, &::after": {
      display: "none",
    },
  });

  const settings = {
    infinite: auctionItems.length > 1,
    speed: 500,
    slidesToShow: auctionItems.length > 1 ? 4 : 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: auctionItems.length > 1 ? 2 : 1,
          slidesToScroll: 1,
          infinite: auctionItems.length > 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <Box sx={{ my: 7 }}>
      <Grid sx={{ mb: 4 }} item container justifyContent="space-between" alignItems="center">
        <Typography sx={{ fontSize: "36px", fontWeight: 500 }} variant="h4">
          {t("hurryUp.title")}
        </Typography>
        <Button
          sx={{
            padding: "14px 32px",
            color: "#3083FF",
            border: "1px solid #3083FF",
            borderRadius: 2,
          }}
          variant="outlined"
          onClick={() => navigate("/lots")}>
          {t("hurryUp.allLots")}
        </Button>
      </Grid>
      <Box style={{ position: "relative" }}>
        <Slider ref={sliderRef} {...settings}>
          {auctionItems.map((item) => (
            <Box
              key={item._id}
              sx={{
                maxWidth: auctionItems.length === 1 ? "288px" : "auto",
                margin: "0 auto",
              }}>
              <Grid item xs={12} lg={3} sm={6} px={0.75}>
                <CardItem item={item} type="buyNow" />
              </Grid>
            </Box>
          ))}
        </Slider>
        {auctionItems.length > 1 && (
          <>
            <StyledIconButton
              className="slick-prev"
              sx={{
                position: "absolute",
                left: "-20px",
                top: "45%",
                transform: "translateY(-50%)",
                color: "black",
                backgroundColor: "white",
                boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
                width: 40,
                height: 40,
                "&:hover": {
                  color: "black",
                  backgroundColor: "white",
                  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
                },
                "&:focus": {
                  color: "black",
                  backgroundColor: "white",
                  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
                },
              }}
              onClick={prevSlide}>
              <ArrowBackIosNewIcon />
            </StyledIconButton>
            <StyledIconButton
              className="slick-next"
              sx={{
                position: "absolute",
                right: "-20px",
                top: "45%",
                transform: "translateY(-50%)",
                color: "black",
                backgroundColor: "white",
                boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
                width: 40,
                height: 40,
                "&:hover": {
                  color: "black",
                  backgroundColor: "white",
                  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
                },
                "&:focus": {
                  color: "black",
                  backgroundColor: "white",
                  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
                },
              }}
              aria-label="next"
              onClick={nextSlide}>
              <ArrowForwardIosIcon />
            </StyledIconButton>
          </>
        )}
      </Box>
    </Box>
  );
}
