import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { useNotifications } from "./NotificationsContext";
import { useAuth } from "./AuthContext";

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const { addNotification } = useNotifications();
  const { user } = useAuth();

  useEffect(() => {
    const socketInstance = io(process.env.REACT_APP_BACKEND_BASE_URL, {
      transports: ["websocket"],
    });

    setSocket(socketInstance);

    socketInstance.on("connect", () => {
      console.log("Connected to WebSocket server");

      if (user && user._id) {
        console.log("Socket connected. Registering user with ID:", user._id);
        socketInstance.emit("register_user", user._id);
      } else {
        console.log("User not available when socket connected:", user);
      }
    });

    socketInstance.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
    });

    const handleEvent = (eventName, data) => {
      console.log(`Получено событие '${eventName}':`, data);

      addNotification({
        id: Date.now(),
        message: data.message || `Событие: ${eventName}`,
        link: data.link || "/",
      });
    };

    // Список событий, которые вы хотите обрабатывать
    const eventsToHandle = [
      "auction_completed",
      "new_message",
      "bid_placed",
      // Добавьте здесь другие события по мере необходимости
    ];

    eventsToHandle.forEach((eventName) => {
      socketInstance.on(eventName, (data) => handleEvent(eventName, data));
    });

    return () => {
      eventsToHandle.forEach((eventName) => {
        socketInstance.off(eventName, handleEvent);
      });
      socketInstance.disconnect();
    };
  }, [addNotification, user]);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
