import React from "react";
import { Box, Button, Modal, Fade, Backdrop } from "@mui/material";
import { useNavigate } from "react-router-dom";
import congratsGif from "../../assets/images/congrats.gif"; // Импорт GIF

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  height: 250,
  borderRadius: 10,
  boxShadow: 24,
  p: 0,
  outline: "none",
  textAlign: "center",
  overflow: "hidden",
};

const CongratsModal = ({ open, handleClose, auction }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    handleClose();
    navigate("/checkout", {
      state: {
        auctionId: auction._id,
        auctionName: auction.name,
        finalPrice: auction.currentPrice,
      },
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <Box sx={modalStyle}>
          <img
            src={congratsGif}
            alt="Поздравления"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "45% center", // Смещаем изображение вправо
              borderRadius: "10px",
            }}
          />
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              bottom: 20,
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "white",
              color: "#3083FF",
              borderRadius: "10px",
              px: 4,
              py: 1,
              fontWeight: 500,
              fontSize: 13,
              textTransform: "none", // Отключаем верхний регистр
    '&:hover': {
      backgroundColor: "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
      color: "white",
      // Вы можете добавить любые другие стили для состояния hover
      borderColor: "white",
      border: 1
    },
            }}
            onClick={handleButtonClick}>
            Перейти к оформлению
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CongratsModal;
