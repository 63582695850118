import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import {
  ArrowDropDown as ArrowDropDownIcon,
  MenuRounded as MenuRoundedIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../Context/AuthContext";
import "./HeaderUnauthorized.scss";
import DrawerHeader from "../DrawerHeader/DrawerHeader";
import Unauthorized from "../Unauthorized/Unauthorized";
import Authorized from "../Authorized/Authorized";
import { fetchCategories } from "../../services/CategoryService";
import BramaLogo from "../../assets/images/BRAMA.png";

export default function HeaderUnauthorized({ onSearch }) {
  const { t } = useTranslation();
  const { user, registrationComplete } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const isSmaller = useMediaQuery("(max-width:1100px)");

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchCategories();
        setCategories(data);
      } catch (error) {
        console.error("Error loading categories:", error);
      }
    };

    loadCategories();
  }, []);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryClick = (categoryId) => {
    handleClose();
    navigate(`/lots?category=${categoryId}`);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const navigateToMain = () => {
    navigate("/");
  };

  const navigateToTerms = () => {
    navigate("/terms");
  };

  const navigateToPayDeliv = () => {
    navigate("/payment-and-delivery");
  };

  const navigateToContacts = () => {
    navigate("/contacts");
  };

  const navigateToLogin = () => {
    navigate("/login");
  };

  return (
    <Box sx={{ flexGrow: 1, alignItems: "center" }}>
      <AppBar position="static" sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
        <Toolbar className="toolbar">
          {isSmaller && (
            <IconButton
              edge="start"
              onClick={toggleDrawer}
              sx={{ mr: 2, ml: 0, color: "#3083FF", p: 0 }}>
              <MenuRoundedIcon sx={{ fontSize: "48px" }} />
            </IconButton>
          )}
          {/* <Typography
            className="logo"
            variant="h6"
            noWrap
            component="a"
            href="#"
            onClick={navigateToMain}
            sx={{
              mr: 2,
              display: { md: "flex" },
              color: "black",
              fontSize: "39px",
              fontWeight: 700,
              textDecoration: "none",
              cursor: "pointer",
            }}>
            
            brama.kz
          </Typography> */
          <img src={BramaLogo} style={{ width: 130 }} alt=""             onClick={navigateToMain}
          />

          }
          {!isSmaller && (
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="#"
                onClick={navigateToMain}
                sx={{
                  p: 1,
                  display: { md: "flex" },
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 400,
                  textDecoration: "none",
                  textAlign: "center",
                  cursor: "pointer",
                }}>
                {t("header.main")}
              </Typography>
              <Typography
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                //disableElevation // у Typography не может быть такого атрибута ?!
                variant="h6"
                noWrap
                component="a"
                href="#"
                sx={{
                  p: 1,
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 400,
                  textDecoration: "none",
                  display: "flex",
                  cursor: "pointer",
                }}>
                {t("header.categories")}
                <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
                  <ArrowDropDownIcon sx={{ pb: "3px" }} />
                </Box>
              </Typography>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl) && open}  // Добавляем проверку на существование anchorEl
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  sx: {
                    mt: 1.5,
                    ml: -1.5,
                    boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
                    borderRadius: 2,
                    minWidth: 165,
                  },
                }}>
                {categories.map((category) => (
                  <MenuItem
                    key={category._id}
                    sx={{ p: 1.5, fontSize: "14px", fontWeight: 400 }}
                    onClick={() => handleCategoryClick(category._id)}>
                    {category.name}
                  </MenuItem>
                ))}
              </Menu>

              <Typography
                variant="h6"
                noWrap
                component="a"
                href="#"
                onClick={navigateToTerms}
                sx={{
                  p: 1,
                  display: { display: "flex" },
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 400,
                  textDecoration: "none",
                  textAlign: "center",
                  cursor: "pointer",
                }}>
                {t("header.terms")}
              </Typography>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="#"
                onClick={navigateToPayDeliv}
                sx={{
                  p: 1,
                  display: { md: "flex" },
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 400,
                  textDecoration: "none",
                  cursor: "pointer",
                }}>
                {t("header.paymentAndDelivery")}
              </Typography>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="#"
                onClick={navigateToContacts}
                sx={{
                  p: 1,
                  display: { md: "flex" },
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 400,
                  textDecoration: "none",
                  cursor: "pointer",
                }}>
                {t("header.contacts")}
              </Typography>
            </Box>
          )}
          {!isSmaller &&
            (user && registrationComplete ? (
              <Authorized />
            ) : (
              <Unauthorized onUserIconClick={navigateToLogin} />
            ))}
        </Toolbar>
      </AppBar>
      {isSmaller && (
        <DrawerHeader
          drawerOpen={drawerOpen}
          toggleDrawer={toggleDrawer}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && open}  // Добавляем проверку на существование 
        />
      )}
    </Box>
  );
}
