import React, { useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import axiosInstance from "../../axiosConfig";
import { Box, Typography, TextField, Button, IconButton, InputAdornment } from "@mui/material";
import {
  VisibilityOutlined as VisibilityOutlinedIcon,
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
} from "@mui/icons-material";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [error, setError] = useState("");
  const { user } = useAuth();

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleChangePassword = async () => {
    if (!newPassword || newPassword.length < 6) {
      setError("Новый пароль должен содержать минимум 6 символов");
      setPasswordChanged(false);
      return;
    }

    if (newPassword === oldPassword) {
      setError("Новый пароль должен отличаться от старого");
      setPasswordChanged(false);
      return;
    }

    try {
      const response = await axiosInstance.put(`/api/users/${user._id}/change-password`, {
        oldPassword,
        newPassword,
      });
      setPasswordChanged(true);
      setError("");
      setOldPassword("");
      setNewPassword("");
    } catch (err) {
      setPasswordChanged(false);
      setError(err.response?.data?.message || "Ошибка при смене пароля");
    }
  };

  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        maxWidth: "460px",
        margin: "0 auto",
        my: 5,
      }}>
      <Typography variant="h4" sx={{ fontSize: "36px", my: 4, fontWeight: 600 }}>
        Сменить пароль
      </Typography>

      <Box sx={{ width: "100%" }}>
        <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Старый пароль</Typography>
        <TextField
          label="Старый пароль"
          variant="outlined"
          fullWidth
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          type={showOldPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowOldPassword}
                  edge="end">
                  {showOldPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              background: "#FFFFFF",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#89939E",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              fontWeight: 400,
            },
          }}
        />

        <Typography sx={{ fontSize: "12px", mt: 3, mb: 1, color: "#717171" }}>
          Новый пароль
        </Typography>
        <TextField
          label="Новый пароль"
          variant="outlined"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          type={showNewPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                  edge="end">
                  {showNewPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              background: "#FFFFFF",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#89939E",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              fontWeight: 400,
            },
          }}
        />

        <Button
          variant="contained"
          fullWidth
          onClick={handleChangePassword}
          sx={{
            my: 3,
            background: "linear-gradient(180deg, #3083FF 0%, #73ABFF 100%)",
            boxShadow: "none",
            borderRadius: "10px",
            py: "12px",
            fontSize: "16px",
            fontWeight: 400,
            textTransform: "none",
          }}>
          Сменить пароль
        </Button>

        {passwordChanged && (
          <Typography sx={{ color: "green", mt: 2 }}>Пароль успешно изменён</Typography>
        )}

        {error && <Typography sx={{ color: "red", mt: 2 }}>{error}</Typography>}
      </Box>
    </Box>
  );
};

export default ChangePassword;
