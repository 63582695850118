import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  MenuItem,
  Grid,
  CircularProgress,
} from "@mui/material";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosConfig";
import { AuctionItemService } from "../../services/AuctionItemService";
import FundsModal from "../../components/FundsModal/FundsModal";
import { WalletService } from "../../services/WalletService";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import InputMask from "react-input-mask";


const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    borderColor: "#89939E",
    "& fieldset": {
      borderColor: "#89939E",
    },
  },
  "& .MuiInputBase-input": {
    py: "10px",
  },
};

const Checkout = () => {
  const [orderId, setOrderId] = useState(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [auction, setAuction] = useState(null);
  const [extraCharge, setExtraCharge] = useState(null);
  const [deliveryMethod, setDeliveryMethod] = useState("Самовывоз с офиса г. Астана");
  const [personalData, setPersonalData] = useState({
    email: "",
    phone: "",
    lastName: "",
    firstName: "",
    middleName: "",
    dob: "",
  });
  const [deliveryData, setDeliveryData] = useState({
    country: "Казахстан",
    city: "",
    street: "",
    houseNumber: "",
    apartment: "",
    postalCode: "",
    note: "",
  });

  const [isFundsModalOpen, setFundsModalOpen] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const handleOpenFundsModal = () => {
    setFundsModalOpen(true);
  };

  const handleCloseFundsModal = () => {
    setFundsModalOpen(false);
  };
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPersonalData = async () => {
      try {
        const response = await axiosInstance.get("/api/users/profile");
        const userData = response.data;
        setPersonalData({
          email: userData.email || "",
          phone: userData.phone || "",
          lastName: userData.lastName || "",
          firstName: userData.firstName || "",
          middleName: userData.middleName || "",
          dob: userData.dob || "",
        });
      } catch (error) {
        console.error("Ошибка при загрузке данных пользователя:", error);
        setError("Не удалось загрузить данные пользователя.");
      }
    };

    fetchPersonalData();
  }, []);

  useEffect(() => {
    const auctionId = state?.auctionId;

    if (!auctionId) {
      console.error("Не удалось найти ID аукциона.");
      setError("Не удалось найти ID аукциона.");
      return;
    }

    const fetchWalletBalance = async () => {
      try {
        const balance = await WalletService.getWalletBalance();
        setWalletBalance(balance);
      } catch (error) {
        console.error("Error fetching wallet balance:", error);
      }
    };
    fetchWalletBalance();

    const fetchAuctionData = async () => {
      try {
        console.log('pidsxuids')
        const auctionData = await AuctionItemService.fetchAuctionItemById(auctionId);
        setAuction(auctionData);
        const winnerBidsData = await AuctionItemService.fetchExtraChargeForItemById(auctionId);
        setExtraCharge(auctionData.currentPrice - ((winnerBidsData.winnerBidsCount * auctionData.increment) + (winnerBidsData.winnerSuperBidsCount * 5000)))
        console.log(winnerBidsData)
        console.log(auctionData)
      } catch (error) {
        console.error("Ошибка при загрузке данных аукциона:", error);
        setError("Не удалось загрузить данные аукциона.");
      }
    };

    fetchAuctionData();
  }, [state]);

  const handlePersonalDataChange = (e) => {
    const { name, value } = e.target;
    setPersonalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDeliveryDataChange = (e) => {
    const { name, value } = e.target;
    setDeliveryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDeliveryChange = (event) => {
    setDeliveryMethod(event.target.value);
  };

  const handlePayment = async () => {
    try {
      console.log("Данные перед отправкой:", {
        deliveryMethod,
        deliveryData,
        personalData,
      });

      const existingOrder = await AuctionItemService.fetchOrderByAuctionId(auction._id);

      if (!existingOrder) {
        throw new Error("Заказ не найден для данного аукциона");
      }

      if (walletBalance < extraCharge) {
        handleOpenFundsModal();
        return;
      }

      await WalletService.deductParticipationFee(extraCharge);

      const updatedBalance = await WalletService.getWalletBalance();
      setWalletBalance(updatedBalance);
console.log(updatedBalance)


      console.log("Оплата прошла успешно!");
      const orderData = {
        deliveryMethod,
        deliveryData: deliveryMethod === "Доставка почтой по Казахстану" ? deliveryData : {},
        personalData,
      };

      console.log("Order Update Data:", orderData);

      const updatedOrder = await AuctionItemService.updateOrder(orderData, existingOrder._id);
      console.log("ID обновлённого заказа:", updatedOrder._id);
      
      await AuctionItemService.markAsPaid(auction._id);
      setOrderId(updatedOrder._id);
      navigate("/profile/my-purchases");
    } catch (error) {
      console.error("Ошибка при обработке оплаты:", error);
      setError("Не удалось обработать оплату. " + error.message);
    }
  };

  useEffect(() => {
    if (orderId) {
      console.log("Обновленный ID заказа:", orderId);
    }
  }, [orderId]);

  if (!auction) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        px: 3,
        py: 5,
        my: 5,
        backgroundColor: "#fff",
        borderRadius: "20px",
      }}>
      <Typography variant="h4" sx={{ fontSize: "36px", mb: 4, fontWeight: 600 }}>
        Оформление
      </Typography>
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <Typography variant="h6" sx={{ fontSize: "20px", fontWeight: 600 }}>
            Персональные данные
          </Typography>
          <Grid container spacing={3} sx={{ my: 3 }}>
            <Grid item xs={12} md={6}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Email *</Typography>
              <TextField
                name="email"
                value={personalData.email}
                onChange={handlePersonalDataChange}
                variant="outlined"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    border: "none",
                    backgroundColor: "#F5F7FA",
                    "& fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-input": {
                    py: "10px",
                  },
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Телефон *</Typography>
              <InputMask
          mask="+7\ (999) 999-99-99"
          value={personalData.phone}
          onChange={handlePersonalDataChange}
    maskChar=" ">
          {() => (
              <TextField
                name="phone"
                value={personalData.phone}
                onChange={handlePersonalDataChange}
                variant="outlined"
                fullWidth
                sx={textFieldStyles}
              />
            )}
        </InputMask>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12} md={6}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Фамилия *</Typography>
              <TextField
                name="lastName"
                value={personalData.lastName}
                onChange={handlePersonalDataChange}
                variant="outlined"
                fullWidth
                sx={textFieldStyles}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Имя *</Typography>
              <TextField
                name="firstName"
                value={personalData.firstName}
                onChange={handlePersonalDataChange}
                variant="outlined"
                fullWidth
                sx={textFieldStyles}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12} md={6}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Отчество</Typography>
              <TextField
                name="middleName"
                value={personalData.middleName}
                onChange={handlePersonalDataChange}
                variant="outlined"
                fullWidth
                sx={textFieldStyles}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                Дата рождения *
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
              <DatePicker
                name="dob"
                value={dayjs(personalData.dob)}
                onChange={(newValue) => setPersonalData((prevData) => ({
                  ...prevData,
                  [personalData.dob]: newValue,
                }))}
                variant="outlined"
                sx={{ width: '100%', ...textFieldStyles }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TodayOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              /></LocalizationProvider>
            </Grid>
          </Grid>
          <Typography variant="h6" sx={{ fontSize: "20px", mt: 3, mb: 2 }}>
            Доставка
          </Typography>
          <RadioGroup value={deliveryMethod} onChange={handleDeliveryChange}>
            <FormControlLabel
              value="Самовывоз с офиса г. Астана"
              control={<Radio />}
              label="Самовывоз с офиса г. Астана"
            />
            <Typography variant="body2" sx={{ ml: 4, mb: 2 }}>
              РК, 010000, г. Астана, ул. Кенесары 40, 4 этаж, офис 15
            </Typography>
            <FormControlLabel
              value="Доставка почтой по Казахстану"
              control={<Radio />}
              label="Доставка почтой по Казахстану"
            />
          </RadioGroup>
          {deliveryMethod === "Доставка почтой по Казахстану" && (
            <Box sx={{ mt: 3 }}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Страна *</Typography>
              <TextField
                name="country"
                value={deliveryData.country}
                onChange={handleDeliveryDataChange}
                select
                variant="outlined"
                fullWidth
                sx={{ ...textFieldStyles, mb: 3 }}>
                <MenuItem value="Казахстан">Казахстан</MenuItem>
              </TextField>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Город *</Typography>
              <TextField
                name="city"
                value={deliveryData.city}
                onChange={handleDeliveryDataChange}
                variant="outlined"
                fullWidth
                sx={{ ...textFieldStyles, mb: 3 }}
              />
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Улица *</Typography>
              <TextField
                name="street"
                value={deliveryData.street}
                onChange={handleDeliveryDataChange}
                variant="outlined"
                fullWidth
                sx={{ ...textFieldStyles, mb: 3 }}
              />
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                Номер дома *
              </Typography>
              <TextField
                name="houseNumber"
                value={deliveryData.houseNumber}
                onChange={handleDeliveryDataChange}
                variant="outlined"
                fullWidth
                sx={{ ...textFieldStyles, mb: 3 }}
              />
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                Квартира/офис *
              </Typography>
              <TextField
                name="apartment"
                value={deliveryData.apartment}
                onChange={handleDeliveryDataChange}
                variant="outlined"
                fullWidth
                sx={{ ...textFieldStyles, mb: 3 }}
              />
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                Почтовый индекс *
              </Typography>
              <TextField
                name="postalCode"
                value={deliveryData.postalCode}
                onChange={handleDeliveryDataChange}
                variant="outlined"
                fullWidth
                sx={{ ...textFieldStyles, mb: 3 }}
              />
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Примечание</Typography>
              <TextField
                name="note"
                value={deliveryData.note}
                onChange={handleDeliveryDataChange}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    borderColor: "#89939E",
                    "& fieldset": {
                      borderColor: "#89939E",
                    },
                  },
                  mb: 3,
                }}
                inputProps={{ maxLength: 200 }}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}>
            <Box>
              <Typography variant="h6" sx={{ fontSize: "14px" }}>
                Товар:
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "bold" }}>
                {auction.name || "Название товара"}
              </Typography>
              <Typography variant="body2" sx={{ mt: 3, fontSize: "14px" }}>
                Стоимость:
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "bold" }}>
                {extraCharge ? `${extraCharge} тг` : "Цена товара"}
              </Typography>
              {error && (
                <Typography color="error" sx={{ mt: 1 }}>
                  {error}
                </Typography>
              )}
              <Button
                variant="contained"
                fullWidth
                onClick={handlePayment}
                sx={{
                  background:
                    "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
                  boxShadow: "none",
                  borderRadius: "10px",
                  py: "10px",
                  fontSize: "16px",
                  fontWeight: 400,
                  textTransform: "none",
                  mt: 2,
                }}>
                Оплатить
              </Button>
            </Box>
          </Box>
        </Grid>
        <FundsModal open={isFundsModalOpen} onClose={handleCloseFundsModal} />
      </Grid>
    </Box>
  );
};

export default Checkout;
