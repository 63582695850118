import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  IconButton,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AuctionItemService } from "../../services/AuctionItemService";
import { fetchCategories } from "../../services/CategoryService";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    border: "1px solid #89939E",
    backgroundColor: "transparent",
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    py: "10px",
  },
};

const AddNewLot = () => {
  const navigate = useNavigate();
  const [productImage, setProductImage] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);
  const [sliderImage, setSliderImage] = useState(null);
  const [useSliderImage, setUseSliderImage] = useState(false);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [startingPrice, setStartingPrice] = useState("");
  const [currentPrice, setCurrentPrice] = useState("");
  const [increment, setIncrement] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const fetchedCategories = await fetchCategories();
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    loadCategories();
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("category", category);
    formData.append("startingPrice", startingPrice);
    formData.append("currentPrice", currentPrice);
    formData.append("increment", increment);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    if (productImage) {
      formData.append("image", productImage);
    }
    if (useSliderImage && sliderImage) {
      formData.append("sliderImage", sliderImage);
    }
    galleryImages.forEach((image) => {
      formData.append("galleryImages", image);
    });

    try {
      await AuctionItemService.createAuction(formData);
      navigate("/admin/lots");
    } catch (error) {
      console.error("Error creating auction:", error);
    }
  };

  const handleProductImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setProductImage(event.target.files[0]);
    }
  };

  const handleSliderImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSliderImage(event.target.files[0]);
    }
  };

  const handleGalleryImagesChange = (event) => {
    if (event.target.files) {
      const newImages = Array.from(event.target.files).map((file) => file);
      setGalleryImages((prevImages) => [...prevImages, ...newImages]);
    }
  };

  const handleRemoveGalleryImage = (index) => {
    setGalleryImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  return (
    <Paper sx={{ borderRadius: "10px", boxShadow: "none", p: 3 }}>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        onClick={handleBack}
        sx={{
          borderRadius: "8px",
          textTransform: "none",
          color: "#3083FF",
          borderColor: "#3083FF",
          fontWeight: 400,
          "&:hover": {
            borderColor: "#3083FF",
            backgroundColor: "rgba(48, 131, 255, 0.08)",
          },
        }}>
        Назад
      </Button>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, my: 3 }}>
        Новый лот
      </Typography>
      <Box sx={{ display: "flex", gap: 3 }}>
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 2 }}>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Наименование</Typography>
            <TextField
              placeholder="Наименование"
              variant="outlined"
              fullWidth
              sx={textFieldStyles}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Категория</Typography>
            <TextField
              select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              variant="outlined"
              sx={{ ...textFieldStyles, width: "100%" }}
              displayEmpty
              SelectProps={{
                displayEmpty: true,
                renderValue: (value) =>
                  categories.find((cat) => cat._id === value)?.name || "Категория",
              }}>
              {categories.map((cat) => (
                <MenuItem key={cat._id} value={cat._id}>
                  {cat.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                Начальная стоимость
              </Typography>
              <TextField
                placeholder="Начальная стоимость"
                variant="outlined"
                fullWidth
                sx={textFieldStyles}
                value={startingPrice}
                onChange={(e) => setStartingPrice(e.target.value)}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                Текущая ставка
              </Typography>
              <TextField
                placeholder="Текущая ставка"
                variant="outlined"
                fullWidth
                sx={textFieldStyles}
                value={currentPrice}
                onChange={(e) => setCurrentPrice(e.target.value)}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Шаг</Typography>
              <TextField
                placeholder="Шаг"
                variant="outlined"
                fullWidth
                sx={textFieldStyles}
                value={increment}
                onChange={(e) => setIncrement(e.target.value)}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                Дата начала
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <DateTimePicker
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                sx={{ width: '100%', ...textFieldStyles }}
                value={dayjs(startDate)}
                onChange={(newValue) => setStartDate(newValue)}
              />
              </LocalizationProvider>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                Дата окончания
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <DateTimePicker
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                sx={{ width: '100%', ...textFieldStyles }}
                value={dayjs(endDate)}
                onChange={(newValue) => setEndDate(newValue)}
              />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
              Краткое описание
            </Typography>
            <TextField
              placeholder="Краткое описание"
              variant="outlined"
              fullWidth
              multiline
              rows={2}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  borderColor: "#89939E",
                  pb: 4,
                  "& fieldset": {
                    borderColor: "#89939E",
                  },
                },
              }}
              inputProps={{ maxLength: 200 }}
              value={shortDescription}
              onChange={(e) => setShortDescription(e.target.value)}
            />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Описание</Typography>
            <ReactQuill
              value={description}
              onChange={setDescription}
              theme="snow"
              placeholder="Описание"
            />
          </Box>
        </Box>
        <Box sx={{ width: 360, display: "flex", flexDirection: "column", gap: 2 }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              background:
                "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
              boxShadow: "none",
              borderRadius: "10px",
              p: "14px 34px",
              fontSize: "14px",
              fontWeight: 400,
              textTransform: "none",
            }}>
            Опубликовать
          </Button>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box sx={{ border: "1px solid #8C8C8C", gap: 2, p: 2, borderRadius: "5px" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500, mb: 2 }} variant="h6">
                Изображение товара
              </Typography>
              {!productImage ? (
                <Button
                  variant="text"
                  component="label"
                  sx={{
                    p: 0,
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#3083FF",
                    textTransform: "none",
                  }}>
                  Задать изображение
                  <input type="file" name="image" hidden onChange={handleProductImageChange} />
                </Button>
              ) : (
                <Box sx={{ position: "relative" }}>
                  <img
                    src={URL.createObjectURL(productImage)}
                    alt="Product"
                    style={{ width: "100%", borderRadius: "5px" }}
                  />
                  <IconButton
                    size="small"
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      borderRadius: "50%",
                    }}
                    onClick={() => setProductImage(null)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </Box>
            <Box sx={{ border: "1px solid #8C8C8C", gap: 2, p: 2, borderRadius: "5px" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500, mb: 2 }} variant="h6">
                Галерея изображений
              </Typography>
              <Button
                variant="text"
                component="label"
                sx={{
                  p: 0,
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#3083FF",
                  textTransform: "none",
                }}>
                Загрузить изображения
                <input
                  type="file"
                  name="galleryImages"
                  hidden
                  multiple
                  onChange={handleGalleryImagesChange}
                />
              </Button>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                {galleryImages.map((image, index) => (
                  <Box key={index} sx={{ position: "relative" }}>
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Gallery ${index}`}
                      style={{ width: "100px", borderRadius: "5px" }}
                    />
                    <IconButton
                      size="small"
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleRemoveGalleryImage(index)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useSliderImage}
                  onChange={(e) => setUseSliderImage(e.target.checked)}
                  color="primary"
                />
              }
              label="Использовать на слайдере"
            />
            {useSliderImage && (
              <Box sx={{ border: "1px solid #8C8C8C", gap: 2, p: 2, borderRadius: "5px" }}>
                <Typography sx={{ fontSize: "14px", fontWeight: 500, mb: 2 }} variant="h6">
                  Изображение для слайдера (1920px * 500px)
                </Typography>
                {!sliderImage ? (
                  <Button
                    variant="text"
                    component="label"
                    sx={{
                      p: 0,
                      fontSize: "14px",
                      fontWeight: 400,
                      color: "#3083FF",
                      textTransform: "none",
                    }}>
                    Задать изображение
                    <input
                      type="file"
                      name="sliderImage"
                      hidden
                      onChange={handleSliderImageChange}
                    />
                  </Button>
                ) : (
                  <Box sx={{ position: "relative" }}>
                    <img
                      src={URL.createObjectURL(sliderImage)}
                      alt="Slider"
                      style={{ width: "100%", borderRadius: "5px" }}
                    />
                    <IconButton
                      size="small"
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        borderRadius: "50%",
                      }}
                      onClick={() => setSliderImage(null)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default AddNewLot;
