import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  MenuItem,
  TextField,
  Grid,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AuctionItemService } from "../../services/AuctionItemService";
import { UserService } from "../../services/UserService";

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    border: "1px solid #89939E",
    backgroundColor: "transparent",
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    py: "10px",
  },
};

const OrderDetail = ({ orderId, onBack, onUpdate }) => {
  const [order, setOrder] = useState(null);
  const [status, setStatus] = useState("");
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState("");

  const [buyerData, setBuyerData] = useState(null);
  const [buyerLoading, setBuyerLoading] = useState(false);
  const [buyerError, setBuyerError] = useState("");

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const fetchedOrder = await AuctionItemService.fetchOrderById(orderId);
        setOrder(fetchedOrder);
        setStatus(fetchedOrder.isPaid ? "Оплачен" : "Не оплачен");
        console.log("Полученный заказ в OrderDetail.jsx:", fetchedOrder);
      } catch (error) {
        console.error("Ошибка при загрузке заказа:", error);
        setError("Не удалось загрузить детали заказа.");
      }
    };

    fetchOrder();
  }, [orderId]);

  useEffect(() => {
    if (order && !order.personalData) {
      const fetchBuyerData = async () => {
        try {
          setBuyerLoading(true);
          const userData = await UserService.fetchUserById(order.buyer);
          setBuyerData(userData);
          console.log("Данные покупателя:", userData);
        } catch (err) {
          console.error("Ошибка при загрузке данных покупателя:", err);
          setBuyerError("Не удалось загрузить данные покупателя.");
        } finally {
          setBuyerLoading(false);
        }
      };
      fetchBuyerData();
    }
  }, [order]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSave = async () => {
    setUpdating(true);
    setError("");
    try {
      const updatedData = status === "Оплачен" ? { isPaid: true } : { isPaid: false };
      const updatedOrder = await AuctionItemService.updateOrderStatus(order._id, updatedData);

      setOrder(updatedOrder);

      if (onUpdate) {
        onUpdate(updatedOrder);
      }

      alert("Статус заказа обновлен успешно!");
    } catch (err) {
      console.error("Ошибка при обновлении статуса заказа:", err);
      setError("Не удалось обновить статус заказа.");
    } finally {
      setUpdating(false);
    }
  };

  const renderPersonalData = () => {
    const data = order?.personalData || buyerData;
    console.log("Используемые персональные данные:", data);

    if (buyerLoading) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
          <CircularProgress />
        </Box>
      );
    }

    if (buyerError) {
      return (
        <Typography color="error" sx={{ mb: 4 }}>
          {buyerError}
        </Typography>
      );
    }

    if (!data) {
      return (
        <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
          Персональные данные не указаны.
        </Typography>
      );
    }

    return (
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={6}>
          <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>
            Электронная почта
          </Typography>
          <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>
            {data.email || "Не указано"}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>Номер телефона</Typography>
          <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>
            {data.phone || "Не указано"}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>Фамилия</Typography>
          <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>
            {data.lastName || "Не указано"}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>Имя</Typography>
          <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>
            {data.firstName || "Не указано"}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>Отчество</Typography>
          <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>
            {data.middleName || "Не указано"}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>Дата рождения</Typography>
          <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>
            {data.dob ? new Date(data.dob).toLocaleDateString() : "Не указано"}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  if (!order) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper sx={{ borderRadius: "10px", p: 3, boxShadow: "none", pb: 0 }}>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        onClick={onBack}
        sx={{
          borderRadius: "8px",
          textTransform: "none",
          color: "#3083FF",
          borderColor: "#3083FF",
          fontWeight: 400,
          mb: 3,
          "&:hover": {
            borderColor: "#3083FF",
            backgroundColor: "rgba(48, 131, 255, 0.08)",
          },
        }}>
        Назад
      </Button>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, mb: 3 }}>
        Детали заказа
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: 600, mb: 5 }}>
            Персональные данные
          </Typography>
          {renderPersonalData()}
          <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: 600, mb: 5 }}>
            Доставка
          </Typography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500, mb: 2 }}>
                Метод доставки: {order.deliveryMethod || "Не указано"}
              </Typography>
            </Grid>
            {order.deliveryMethod === "Доставка почтой по Казахстану" && order.deliveryData ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>Страна</Typography>
                  <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>
                    {order.deliveryData.country || "Не указано"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>Город</Typography>
                  <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>
                    {order.deliveryData.city || "Не указано"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>Улица</Typography>
                  <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>
                    {order.deliveryData.street || "Не указано"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>
                    Номер дома
                  </Typography>
                  <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>
                    {order.deliveryData.houseNumber || "Не указано"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>
                    Квартира/офис
                  </Typography>
                  <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>
                    {order.deliveryData.apartment || "Не указано"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>
                    Почтовый индекс
                  </Typography>
                  <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>
                    {order.deliveryData.postalCode || "Не указано"}
                  </Typography>
                </Grid>

                {order.deliveryData.note && (
                  <Grid item xs={12}>
                    <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>
                      Примечание
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>
                      {order.deliveryData.note}
                    </Typography>
                  </Grid>
                )}
              </>
            ) : (
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>Адрес не указан.</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: 600, mb: 5 }}>
            Информация о товаре
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontSize: "12px", color: "#717171" }}>Товар</Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 5 }}>
              {order.productData.name || "Не указано"}
            </Typography>
            <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>Цена</Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 5 }}>
              {order.productData.price ? `${order.productData.price} тг` : "Не указано"}
            </Typography>
            <Box>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                Статус оплаты
              </Typography>
              <TextField
                select
                value={status}
                onChange={handleStatusChange}
                variant="outlined"
                sx={{ ...textFieldStyles, width: "100%" }}
                displayEmpty
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (value) => value || "Выбрать статус",
                }}>
                <MenuItem sx={{ fontWeight: 500, py: 1 }} value="Оплачен">
                  Оплачен
                </MenuItem>
                <MenuItem sx={{ fontWeight: 500, py: 1 }} value="Не оплачен">
                  Не оплачен
                </MenuItem>
              </TextField>
            </Box>
            {error && (
              <Typography color="error" sx={{ mt: 1 }}>
                {error}
              </Typography>
            )}
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={updating}
              sx={{
                width: "100%",
                background:
                  "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
                boxShadow: "none",
                borderRadius: "10px",
                p: "14px 34px",
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "none",
                mt: 2,
              }}>
              {updating ? <CircularProgress size={24} color="inherit" /> : "Сохранить"}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OrderDetail;
